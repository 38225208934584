const EducationContent = {
  article1: {
    title: 'The Biochemical Life Cycle of a Breath',
    date: '1/25/24',
    mdLink: '/education-content/breath-biochemistry-overview.md'
  },
  article2: {
    title: 'Carbon Dioxide and Breathing',
    date: '1/27/24',
    mdLink: '/education-content/carbon-dioxide-overview.md'
  },
  article3: {
    title: 'Carbon Dioxide Tolerance Test',
    date: '1/27/24',
    mdLink: '/education-content/carbon-dioxide-tolerance-test.md'
  },
  article4: {
    title: 'Increased CO₂ Tolerance for Enhanced Oxygen Utilization',
    date: '1/30/24',
    mdLink: '/education-content/co2-tolerance-improvement.md'
  },
  article5: {
    title: 'Wim Hof Breathing: A Biochemical Perspective',
    date: '1/31/24',
    mdLink: '/education-content/wim-hof-biochemical-perspective.md'
  },
  article6: {
    title: 'The Physiology of Respiratory Regulation',
    date: '2/2/24',
    mdLink: '/education-content/respiratory-regulation.md'
  },

  // Add more articles or content here
};

export default EducationContent;