import React from 'react';
import SubscriptionForm from './SubscriptionForm'
import './Footer.css'; 


const Footer = () => {
  return (
    <footer className="App-footer">
    <SubscriptionForm />  

    </footer>
  );
}

export default Footer;
